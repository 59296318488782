import './App.css';
import {createRef, useEffect, useState} from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import useInterval from "use-interval";
import 'swiper/swiper.css';
import cartaLogo from './img/carta_logo.png';


import {
    Card,
    Container,
    Toolbar,
    Typography,
    Grid,
    Box,
    Button,
    TextField,
} from '@mui/material';
import useSound from 'use-sound';

import {problems} from './probrems';
import {createValidInputs} from "./validInputs";
import typingSound from "./audio/typing.mp3";
import correctSound from "./audio/correct.mp3";
import missSound from "./audio/miss.mp3";
import bgmSound from "./audio/bgm.mp3";

const LIMIT_TIME = 60;
const INTERVAL = 0.1;

problems.sort(function(a, b) {
  return Math.random() - 0.5;
});

function App() {
    const [playerName, setPlayerName] = useState('');
    const [isPlaying, setIsPlaying] = useState(false);
    const [limitTime, setLimitTime] = useState(LIMIT_TIME);
    const [problemNum, setProblemNum] = useState(0);
    const [problem, setProblem] = useState(problems[problemNum]);
    const [answerList, setAnswerList] = useState([]);
    const [inputtingStrings, setInputtingStrings] = useState('');
    const [isFinish, setIsFinish] = useState(false);
    const [inputtedStringsNum, setInputtedStringsNum] = useState(0);
    const appRef = createRef();
    const buttonRef = createRef();
    const [swiper, setSwiper] = useState(null);
    const [typingPlay] = useSound(typingSound);
    const [correctPlay] = useSound(correctSound);
    const [missPlay] = useSound(missSound);
    const [bgmPlay] = useSound(bgmSound, {loop: true});


    useEffect(() => {
        setAnswerList(createValidInputs(problem.hiragana));
    }, []);

    useInterval(() => {
        setLimitTime(Math.max(limitTime - INTERVAL, 0));
        if (limitTime <= 0) {
            finish();
        }
    }, isPlaying ? INTERVAL * 1000 : null);

    const start = () => {
        bgmPlay();
        setAnswerList(createValidInputs(problem.hiragana));
        appRef.current.focus();
        setIsPlaying(true);
    }

    const dummyStart = () => {
      bgmPlay();
    }

    const finish = () => {
        setIsFinish(true);
        setIsPlaying(false);
    }

    const onTyping = (event) => {
        if (!isPlaying) {
            return;
        }

        const input = event.key;

        const inputting = inputtingStrings + input;
        const existingAnswerList = answerList.filter(answer => answer.startsWith(inputting));
        if (existingAnswerList.length === 0) {
            missPlay();
            return;
        }

        typingPlay();
        if (existingAnswerList.some((answer) => answer === inputtingStrings + input)) {
            correctPlay();
            const nextProblemNum = problemNum + 1;
            setInputtedStringsNum(inputtedStringsNum + (inputtingStrings + input).length);
            if (nextProblemNum >= problems.length) {
                finish();
                return;
            }

            nextProblem(nextProblemNum)
            return;
        }

        setInputtingStrings((pre) => pre + input);
        setAnswerList(existingAnswerList);
    }

    const nextProblem = (nextProblemNum) => {
        swiper.slideNext();
        setProblemNum(nextProblemNum);
        setProblem(problems[nextProblemNum]);
        setInputtingStrings("");
        setAnswerList(createValidInputs(problems[nextProblemNum].hiragana));
    }

    const onChangePlayerName = (event) => {
        setPlayerName(event.target.value);
    }

    const reset = () => {
        window.location.reload();
    }

    const problemCards = problems.map((problem, index) => {
        return (
          <Box sx={{height: "50%"}}>
            <SwiperSlide virtualIndex={index}>
                <Box sx={{padding: '10px'}}>
                    <img src={problem.logo} height="62px"/>
                    <Typography component="h3" variant="h4" sx={{marginTop: "30px", whiteSpace: "nowrap"}}>{problem.problem}</Typography>
                </Box>
            </SwiperSlide>
          </Box>
        );
    });

    const content = () => {
        if (isFinish) {
            return (
                <>
                    <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%"}}>
                        <Box>
                            <Typography variant="h3" sx={{marginBottom: "20px"}}>ゲーム終了！</Typography>
                            <Typography variant="h4">ユーザー名: {playerName}</Typography>
                            <Typography variant="h5">正しく打ったキーの数: {inputtedStringsNum}</Typography>
                            <Typography variant="h5">残り時間: {Math.floor(limitTime)}秒</Typography>
                            <Typography variant="h4">得点: {Math.floor(inputtedStringsNum * (1 + limitTime / LIMIT_TIME))}点</Typography>
                            <Button variant="outlined" sx={{marginTop: "20px"}} onClick={reset}>戻る</Button>
                        </Box>
                    </Box>
                </>
            )
        } else if (!isPlaying) {
            return (
                <>
                    <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%"}}>
                        <Box>
                            <Typography variant="h4" sx={{marginBottom: "20px"}}>ニックネームを入力してチャレンジ！</Typography>
                            <form>
                                <TextField value={playerName} onChange={onChangePlayerName} placeholder="名前を入力" size="small" sx={{marginRight: "10px"}} />
                                <Button type="submit" onClick={start} variant="outlined">start</Button>
                            </form>
                        </Box>
                    </Box>
                </>
            )
        } else {
            return (
                <>
                    <Box sx={{padding: "20px"}}>
                        <Grid container justifyContent="left" sx={{marginBottom: "20px"}}>
                            <Typography variant="h4">残り{Math.floor(limitTime)}秒</Typography>
                        </Grid>
                        <Swiper onSwiper={(swiper) => setSwiper(swiper)}>
                            {problemCards}
                        </Swiper>
                        <Box>
                              <Typography variant="h5">{problem.hiragana}</Typography>
                              <Typography variant="h5"><span style={{opacity: "0.5"}}>{inputtingStrings}</span>{answerList[0].slice(inputtingStrings.length)}</Typography>
                        </Box>
                    </Box>
                </>
            )
        }
    }


    return (
        <div className="App" onKeyDown={(e) => onTyping(e)} tabIndex={0} ref={appRef} >
            <Toolbar sx={{borderBottom: 1, borderColor: 'divider'}}>
                <img width="160px" src={cartaLogo} />
            </Toolbar>
            <div style={{height: "calc(100vh - 64px)", background: "#444", paddingTop: "50px", paddingBottom: "50px"}}>
                <Container disableGutters={true} sx={{width: "50%", background: "#fff", height: "400px", padding: "0"}}>
                    <main style={{width: "100%", height: "100%"}}>
                        {content()}
                    </main>
                </Container>
            </div>
            <button ref={buttonRef} onClick={dummyStart} style={{dislpay: "none"}}></button>
        </div>
    );
}

export default App;
