import cartaLogo from '../img/carta_logo.png';
import cartaFullCycleLogo from '../img/carta_full_cycle.png';
import digicoLogo from '../img/digico_logo.png';
import kamigameLogo from '../img/kamigame.png';
import kotobankLogo from '../img/kotobank_logo.png';
import spzLogo from '../img/spz_logo.png';
import spzInternRank25 from '../img/spz_intern_rank_25.png';
import sunrise from '../img/sunrise.jpg';
import techvision from '../img/techvision.png';
import treasure from '../img/treasure.jpg';

export const problems = [
  {
    logo: kamigameLogo,
    problem: '神ゲー攻略でゲームマスターになろう',
    hiragana: 'かみげーこうりゃくでげーむますたーになろう',
  },
  { logo: cartaLogo, problem: '挑戦しよう', hiragana: 'ちょうせんしよう' },
  { logo: cartaLogo, problem: 'スピードは力', hiragana: 'すぴーどはちから' },
  {
    logo: cartaLogo,
    problem: '全誠実でいよう',
    hiragana: 'ぜんせいじつでいよう',
  },
  { logo: cartaLogo, problem: '違いを強さに', hiragana: 'ちがいをつよさに' },
  { logo: cartaLogo, problem: '越えよう', hiragana: 'こえよう' },
  {
    logo: cartaLogo,
    problem: 'いつだって楽しく',
    hiragana: 'いつだってたのしく',
  },
  { logo: cartaLogo, problem: '進化推進業', hiragana: 'しんかすいしんぎょう' },
  {
    logo: cartaLogo,
    problem: '東証プライムにいるよ！いえい！',
    hiragana: 'とうしょうぷらいむにいるよ！いえい！',
  },
  {
    logo: cartaLogo,
    problem: '会長はうさぽんです',
    hiragana: 'かいちょうはうさぽんです',
  },
  {
    logo: techvision,
    problem: 'フルサイクル開発',
    hiragana: 'ふるさいくるかいはつ',
  },
  {
    logo: techvision,
    problem: 'フルサイクルエンジニア',
    hiragana: 'ふるさいくるえんじにあ',
  },
  {
    logo: techvision,
    problem: '人にもっと創造的な仕事を',
    hiragana: 'ひとにもっとそうぞうてきなしごとを',
  },
  { logo: techvision, problem: '本質志向', hiragana: 'ほんしつしこう' },
  {
    logo: techvision,
    problem: '共に信頼し共に創る',
    hiragana: 'ともにしんらいしともにつくる',
  },
  {
    logo: techvision,
    problem: '価値を届け続ける',
    hiragana: 'かちをとどけつづける',
  },
  { logo: techvision, problem: '質は速さ', hiragana: 'しつははやさ' },
  {
    logo: techvision,
    problem: '推測するな計測せよ',
    hiragana: 'すいそくするなけいそくせよ',
  },
  {
    logo: techvision,
    problem: '先人に感謝し還元する',
    hiragana: 'せんじんにかんしゃしかんげんする',
  },
  { logo: techvision, problem: '毎日試す', hiragana: 'まいにちためす' },
  {
    logo: techvision,
    problem: '最良のコードはコードなし',
    hiragana: 'さいりょうのこーどはこーどなし',
  },
  {
    logo: spzLogo,
    problem: 'サポーターズはCARTAのサービスです',
    hiragana: 'さぽーたーずはCARTAのさーびすです',
  },
  {
    logo: kotobankLogo,
    problem: 'コトバンクはCARTAのサービスです',
    hiragana: 'ことばんくはCARTAのさーびすです',
  },
  {
    logo: digicoLogo,
    problem: 'デジコはCARTAのサービスです',
    hiragana: 'でじこはCARTAのさーびすです',
  },
  {
    logo: treasure,
    problem: 'Treasure応募待ってます！',
    hiragana: 'Treasureおうぼまってます！',
  },
  {
    logo: sunrise,
    problem: 'Sunriseでインフラマスターになろう！',
    hiragana: 'Sunriseでいんふらますたーになろう！',
  },
  {
    logo: cartaFullCycleLogo,
    problem: '事業進化のためにフルサイクル開発',
    hiragana: 'じぎょうしんかのためにふるさいくるかいはつ',
  },
  {
    logo: spzInternRank25,
    problem: 'SPZサマーインターンランキング2位！',
    hiragana: 'SPZさまーいんたーんらんきんぐ2い！',
  },
];
